/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';

import Trafic from './Trafic';
import Webcam from './Webcam';
import Wallpaper from './Wallpaper';
import Train from './Train';
import Home, { Weather, Graph } from './Weather';
import Clock from './Clock';

const credentials = require('./crendential.json');

function App() {
	const [timer, setTimer] = useState(0);
	const [isPaused, setIsPaused] = useState(false);

	const [trains, setTrains] = useState([]);
	const [vehicleJourney, setVehicleJourney] = useState([]);
	const [error, setError] = useState(null);
	const [weatherData, setWeatherData] = useState(null);

	const fetchData = async (url, setData, handleError) => {
		try {
			const response = await fetch(url);
			const data = await response.json();
			setData(data);
		} catch (error) {
			handleError && handleError(error);
		}
	};

	const getWeather = () => {
		const url = `https://api.openweathermap.org/data/2.5/forecast?lat=48.8333469&lon=1.9511243&appid=${credentials.API_weather}&units=metric&lang=fr`;
		fetchData(url, data => {
			setWeatherData(data);
		}, () => setWeatherData(null));
	};

	const getVehicleJourney = async (id) => {
		try {
			const url = `https://navika.fr/vehicle_journey/${id}`;
			const response = await fetch(url);
			const data = await response.json();
			const current = data.vehicle_journey.stop_date_time.map(e => e.id).indexOf("IDFM:64199") + 1;

			return data.vehicle_journey.stop_date_time.slice(current);
		} catch (error) {
			return null;
		}
	};

	const getTrain = () => {
		const url = 'https://navika.fr/schedules/IDFM:64199?l=IDFM:C01736';
		fetchData(url, data => {
			if (data.error) {
				setError(data.error === '200' ? 'Récupération des trains impossible.' : data.error_message);
			} else {
				setTrains(data.schedules);
			}
		}, () => setError('Récupération des trains impossible.'));
	};

	useEffect(() => {
		if (!trains.length || !trains.length > 1) return;
		const fetchVehicleJourneys = async () => {
			const journeys = await Promise.all(
				trains[0]
					.departures
					.filter((departure) => departure.informations.message !== 'terminus')
					.slice(0, 6)
					.map((train) => getVehicleJourney(train.informations.name))
			);
			setVehicleJourney(journeys);
		};
		fetchVehicleJourneys();
	}, [trains]);

	useEffect(() => {
		getTrain();
		getWeather();

		const intervalTimer = setInterval(() => {
			if (!isPaused) {
				setTimer(timer => (timer + 1000) % 55000);
			}
		}, 1000);

		const intervalTrain = setInterval(getTrain, 1000 * 60 * 1);
		const intervalWeather = setInterval(getWeather, 1000 * 60 * 60);

		return () => {
			clearInterval(intervalTimer);
			clearInterval(intervalTrain);
			clearInterval(intervalWeather);
		};
	}, [isPaused]);

	const pause = () => {
		setIsPaused(!isPaused);
	}

	const classes = () => {
		if (timer < 1000 * 10) return 'home';
		if (timer < 1000 * 25) return 'home graph';
		// if (timer < 1000 * 40) return 'home weather';
	};

	// return <div className={classes()}>
	// 	<Clock />
	// 	<Webcam />
	// </div>

	// return <div className={classes()}>
	// 	<Clock />
	// 	<Trafic trafic={trains} />
	// 	<Train
	// 		trains={trains}
	// 		vehicleJourney={vehicleJourney}
	// 		error={error}
	// 	/>
	// </div>

	return (
		<div className={classes()} onClick={pause}>
			<Clock 
				isPaused={isPaused}
			/>
			{timer < 1000 * 25 ? (
				<>
					<Wallpaper weatherData={weatherData} />
					<Home weatherData={weatherData} />
					<Graph weatherData={weatherData} />
					{/* <Weather weatherData={weatherData} /> */}
				</>
			) : timer < 1000 * 40
				? <>
					<Trafic trafic={trains} />
					<Train
						trains={trains}
						vehicleJourney={vehicleJourney}
						error={error}
					/>
				</>
				: <Webcam />
			}
		</div>
	);
}

export default App;
