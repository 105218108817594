import React, { useState, useEffect, useRef } from 'react';
import maintenance from './assets/img/maintenance.png';

const Trains = ({ trains, vehicleJourney, error }) => {
    return (
        <div className="departures">
            {trains && trains.length ? (
                trains[0]
                    .departures
                    .filter((departure) => departure.informations.message !== 'terminus')
                    .map((departure, i) => (
                        <Train
                            key={departure.informations.id}
                            train={departure}
                            line={trains[0]}
                            vehicleJourney={vehicleJourney}
                            index={i}
                        />
                    ))
            ) : (
                <div className='maintenance'>
                    <img src={maintenance} alt="Maintenance" />
                    <br />
                    <b>Quelque chose s'est mal passé</b>
                    {error && (
                        <>
                            <br />
                            {error}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const Train = ({ train, line, index, vehicleJourney }) => {
    if (train.informations.message === 'terminus')
        return null;

    return (<>
        <div className={`${index < 2 && 'large'} train`} style={{ backgroundColor: `#${line.color}22` }} >
            <img src='class/N.png' className='img' />
            <span className={"diode"} style={{ width: 60, textAlign: 'center' }}>
                {train.informations.headsign}
            </span>
            <TimeBlock
                time={train.stop_date_time.departure_date_time}
                base={train.stop_date_time.base_departure_date_time}
                state={getState(train)}
                track={train.stop_date_time.platform}
            />
            <div style={{ width: '100%' }}>
                <div className='is-flex is-align-items-center gap-1'>
                    <StateBlock
                        time={train.stop_date_time.departure_date_time}
                        base={train.stop_date_time.base_departure_date_time}
                        state={getState(train)}
                        track={train.stop_date_time.platform}
                    />
                    <Direct
                        vehicleJourney={vehicleJourney}
                        index={index}
                        line={line}
                    />
                    <b className={`dest ${getState(train)}`}>
                        {train.informations.direction.name}
                    </b>
                </div>
                {
                    index < 2 && vehicleJourney[index] && <Stops
                        vehicleJourney={vehicleJourney}
                        index={index}
                        line={line}
                    />
                }
            </div>
        </div>

        <div className={'bottomBar'} style={{ backgroundColor: `#${line.color}` }} /></>
    );
};

const Direct = ({ vehicleJourney, index, line }) => {
    if (!vehicleJourney[index] || vehicleJourney[index].length > 2)
        return null;

    return <span
        className='tag is-flex is-align-items-center'
        style={{ backgroundColor: `#${line.color}` }}
    >
        <span className='dot'>
            <span className='dot-inner' />
            <span className='dot-outer' />
            <span className='dot2-inner' />
            <span className='dot2-outer' />
        </span>
        <span style={{ position: 'relative', left: '-3vh' }}>
            Direct
        </span>
    </span>;
}

const Stops = ({ vehicleJourney, index, line }) => {
    const [count, setCount] = useState(null);
    const [stopsHeight, setStopsHeight] = useState(null);
    const [lineHeight, setLineHeight] = useState(null);
    const [containerHeight, setContainerHeight] = useState(null);

    const stopsRef = useRef(null);
    const stopRef = useRef(null);
    const containerRef = useRef(null);

    console.log(vehicleJourney);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count => count + 1);
            getStopsHeight();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const getStopsHeight = () => {
        if (stopsRef.current) {
            setStopsHeight(stopsRef.current.getBoundingClientRect().height);
        }
        if (document.querySelector('.stop')) {
            setLineHeight(document.querySelector('.stop').getBoundingClientRect().height);
        }
        if (containerRef.current) {
            setContainerHeight(containerRef.current.getBoundingClientRect().height);
        }
    };

    function getStyle() {
        if (!stopsHeight || !lineHeight || !containerHeight) {
            return { top: `-0px` };
        }
        const nb_line = stopsHeight / lineHeight;
        const height = (containerHeight * count) % (containerHeight * nb_line);
        return { top: `-${height}px` };
    }

    function getStopHeight() {
        return { height: `${containerHeight}px` };
    }

    function getAnim() {
        const nb_line = stopsHeight / lineHeight;
        const height = (containerHeight * count) % (containerHeight * nb_line);
        if (height == 0) {
            return 'init';
        }
        return '';
    }

    if (vehicleJourney[index].length < 1)
        return < div className='stops-container'>
            <div className={`stops`}>
                <span className='stop ml-3'>Desserte indisponible</span>
            </div>
        </div>

    return <div className='stops-container' ref={containerRef} >
        <div className={`stops ${getAnim()}`} ref={stopsRef} style={getStyle()}>
            {vehicleJourney[index].map((stop, i) => (
                <span className='stop' ref={stopRef} key={i} style={getStopHeight()}>
                    <span className='dot'>
                        <span className='dot-inner' style={{ backgroundColor: `#${line.color}` }} />
                        <span className='dot-outer' style={{ backgroundColor: `#${line.color}` }} />
                    </span>
                    {
                        i == vehicleJourney[index].length - 1
                            ? <span className='last' style={{ backgroundColor: `#${line.color}` }} >
                                {stop.name}
                            </span>
                            : <span>
                                {stop.name}
                            </span>
                    }
                </span>
            ))}
        </div>
    </div>;
}

const TimeBlock = ({ time, state, base }) => {
    return <div className={`is-flex is-align-items-end`}>
        {time !== '' && time.length > 1 && (
            <div
                className='departure'
            >
                <span className='dep'
                    style={{
                        color: getDeparturesColorByState(getTimeDifference(time) >= 0 ? state : 'theorical'),
                    }}
                >
                    {getTime(base)}
                </span>
            </div>
        )}
    </div>;
};

const StateBlock = ({ time, state, base }) => {
    if (state == 'delayed')
        return <div className='state delayed'>
            <span>
                +{getLate({ stop_date_time: { departure_date_time: time, base_departure_date_time: base } })} min
            </span>
        </div>

    if (state == 'canceled')
        return <div className='state canceled'>
            <span> Supprimé </span>
        </div>
}

function getTimeDifference(time) {
    const dttime = new Date(time);
    const dtnow = new Date();

    const diff = dttime - dtnow;

    return Math.floor(diff / 60000); // Convert milliseconds to minutes
}

function getState(train) {
    const state = train.stop_date_time.state;

    const res = [];
    if (getLate(train) > 0) {
        res.push('delayed');
    } else {
        res.push(state)
    }

    if (res.length == 0) {
        res.push('ontime');
    }
    return res;
}

function getLate(train) {
    const departure = train.stop_date_time.departure_date_time;
    const expectedDeparture = train.stop_date_time.base_departure_date_time;

    if (!departure || !expectedDeparture) {
        return 0;
    }

    const dttime = new Date(departure);
    const dtexpe = new Date(expectedDeparture);
    const diff = (dttime - dtexpe) / (1000 * 60); // Difference in minutes

    return Math.round(diff);
}

function getTime(time) {
    if (time === '') {
        return '';
    }

    let dttime = new Date(time);

    const dthour = dttime.getHours() < 10 ? `0${dttime.getHours()}` : dttime.getHours().toString();
    const dtminute = dttime.getMinutes() < 10 ? `0${dttime.getMinutes()}` : dttime.getMinutes().toString();

    return `${dthour}:${dtminute}`;
}

function getDeparturesColorByState(state) {
    if (state.length === 1 && state.includes('theorical')) {
        return '#a9a9a9';
    }

    return '#fcc900';
}

export default Trains;
